export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  divider?: boolean;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  //---- Fathers --------------------------
  {
    id: "Menu",
    title: "Menu",
    messageId: "sidebar.menu",
    type: "collapse",
    icon: "dashboard",
    auth: ["father", "ppa", "appa"],
    children: [
      {
        id: "pilot-detail",
        icon: "school",
        title: "PilotDetail",
        messageId: "pilotDetails.title",
        type: "item",
        auth: ["father", "ppa", "appa"],
        url: "/pilotDetail/[id]",
        exact: true,
      },
      {
        id: "studentProgress",
        icon: "person",
        title: "sidebar.menulinks.studentProgress",
        messageId: "sidebar.menulinks.studentProgress",
        type: "item",
        auth: ["father", "ppa", "appa"],
        url: `/student-progress/[id]`,
        exact: true,
      },
      {
        id: "userAccountBalance",
        icon: "monetization_on",
        title: "sidebar.menulinks.userAccountBalance",
        messageId: "sidebar.menulinks.userAccountBalance",
        type: "item",
        auth: ["father", "ppa", "appa"],
        url: `/user-account-balance-detail/[id]`,
        exact: true,
      },
    ],
  },

  //---- Other Users ------------------------
  {
    id: "Menu",
    title: "Menu",
    messageId: "sidebar.menu",
    type: "collapse",
    icon: "dashboard",
    auth: [
      "super-admin",
      "admin",
      "administrative",
      "instructor-chief",
      "tutor",
      "sales",
    ],
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        messageId: "sidebar.menulinks.dashboard",
        type: "item",
        auth: ["super-admin", "admin", "tutor", "sales"],
        url: "/dashboard",
      },
      {
        id: "academic-progress",
        title: "Academic Progress",
        messageId: "sidebar.menulinks.academicProgress",
        type: "item",
        auth: ["super-admin", "admin", "tutor", "sales"],
        url: "/academic-progress/[id]",
      },
      {
        id: "dashboard-administrative",
        title: "Dashboard",
        messageId: "sidebar.menulinks.dashboard",
        type: "item",
        auth: ["administrative"],
        url: "/dashboard-administrative",
      },
      {
        id: "weekly-report",
        title: "Weekly Report",
        messageId: "sidebar.menulinks.weeklyReport",
        type: "item",
        auth: [
          "instructor-chief",
          "super-admin",
          "admin",
          "tutor",
          "administrative",
          "sales",
        ],
        url: "/weekly-report",
      },
      {
        id: "monthly-hours",
        title: "Monthly Hours",
        messageId: "sidebar.menulinks.monthlyHours",
        type: "item",
        auth: ["instructor-chief", "super-admin", "admin"],
        url: "/monthly-hours",
      },
      {
        id: "availability",
        title: "Disponibilidad",
        messageId: "sidebar.menulinks.availability",
        type: "collapse",
        auth: ["super-admin", "admin", "administrative"],

        children: [
          {
            id: "list",
            title: "Listado",
            messageId: "sidebar.menulinks.list",
            type: "item",
            auth: ["super-admin", "admin", "administrative"],
            url: "/availability",
          },
          {
            id: "bookings",
            title: "Reservas",
            messageId: "sidebar.menulinks.bookings",
            type: "item",
            auth: ["super-admin", "admin", "administrative"],
            url: "/bookings",
          },
        ],
      },
      {
        id: "late-arrivals",
        title: "Late Arrivals",
        messageId: "sidebar.menulinks.lateArrivals",
        type: "item",
        auth: [
          "administrative",
          "instructor-chief",
          "super-admin",
          "admin",
          "sales",
        ],
        url: "/late-arrivals",
      },
      {
        id: "pilots",
        title: "Pilots",
        messageId: "sidebar.menulinks.pilots",
        type: "item",
        auth: ["instructor-chief", "super-admin", "admin", "tutor", "sales"],
        url: "/pilots",
      },
      {
        id: "deals",
        title: "Students Deals",
        messageId: "sidebar.menulinks.deals",
        type: "item",
        auth: ["super-admin", "admin", "tutor", "sales"],
        url: "/deals",
        exact: true,
      },
      {
        id: "alerts",
        title: "Alerts",
        messageId: "sidebar.menulinks.alerts",
        type: "item",
        auth: ["super-admin", "admin"],
        url: "/alerts",
        exact: true,
      },
      {
        id: "stats",
        title: "Stats",
        messageId: "sidebar.menulinks.stats",
        type: "item",
        auth: ["super-admin", "sales"],
        url: "/stats",
      },
      {
        id: "checkin",
        title: "Checkin",
        messageId: "sidebar.menulinks.checkin",
        type: "item",
        auth: ["administrative", "super-admin", "admin"],
        url: "/checkin",
      },
    ],
  },
  {
    id: "academic",
    title: "Academic",
    messageId: "sidebar.menulinks.academic",
    type: "collapse",
    icon: "school",
    auth: ["super-admin", "tutor", "admin", "sales"],
    children: [
      {
        id: "advance-courses",
        title: "advance courses",
        messageId: "sidebar.menuLinks.advanceCourses",
        type: "item",
        auth: ["super-admin", "tutor", "admin", "sales"],
        url: "/advance-courses",
        exact: true,
      },
      {
        id: "courses",
        title: "Courses",
        messageId: "sidebar.menulinks.courses",
        type: "item",
        auth: ["super-admin", "tutor", "admin", "sales"],
        url: "/courses",
        exact: true,
      },
      {
        id: "shifts",
        title: "Shifts",
        messageId: "sidebar.menulinks.shifts",
        type: "item",
        auth: ["super-admin", "tutor", "admin", "sales"],
        url: "/shifts",
        exact: true,
      },
    ],
  },
  {
    id: "Ingresos",
    title: "sidebar.menulinks.inflows",
    messageId: "sidebar.menulinks.inflows",
    type: "collapse",
    icon: "monetization_on",
    auth: ["super-admin", "finance", "administrative", "sales"],
    children: [
      {
        id: "create-inflow",
        title: "Registrar ingreso",
        messageId: "sidebar.createInflow",
        type: "item",
        auth: ["super-admin", "finance", "administrative", "sales"],
        url: "/inflows/create",
        exact: true,
      },
      {
        id: "view-inflows",
        title: "Ver ingresos",
        messageId: "sidebar.viewInflows",
        type: "item",
        auth: ["super-admin", "finance", "administrative", "sales"],
        url: "/inflows",
        exact: true,
      },
    ],
  },
  {
    id: "Compras",
    title: "sidebar.outflowsAlt",
    messageId: "sidebar.outflowsAlt",
    type: "collapse",
    icon: "shopping_cart",
    auth: [
      "super-admin",
      "finance",
      "administrative",
      "accommodation-manager",
      "sales",
    ],
    children: [
      {
        id: "outflowDashboard",
        title: "Dashboard",
        messageId: "sidebar.menulinks.outflowsDashboard",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/outflow-dashboard",
        exact: true,
      },
      {
        id: "outflows-create",
        title: "Outflows",
        messageId: "sidebar.createOutflow",
        type: "item",
        auth: [
          "super-admin",
          "finance",
          "administrative",
          "accommodation-manager",
        ],
        url: "/outflows/create",
        exact: true,
      },
      {
        id: "outflows",
        title: "Outflows",
        messageId: "sidebar.viewGroupedOutflows",
        type: "item",
        auth: [
          "super-admin",
          "finance",
          "administrative",
          "accommodation-manager",
        ],
        url: "/outflows",
        exact: true,
      },
      {
        id: "outflows-items",
        title: "Outflows Items",
        messageId: "sidebar.viewItemsOutflows",
        type: "item",
        auth: [
          "super-admin",
          "finance",
          "administrative",
          "accommodation-manager",
        ],
        url: "/outflows-items",
      },
      {
        id: "outflowTaxes",
        title: "Outflow Taxes",
        messageId: "sidebar.menulinks.outflowTaxes",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/outflows-taxes",
        exact: true,
      },
      {
        id: "payments-create",
        title: "Payments",
        messageId: "sidebar.createPayment",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/payments/create",
        exact: true,
      },
      {
        id: "payments",
        title: "Payments",
        messageId: "sidebar.viewPayments",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/payments",
        exact: true,
      },
      {
        id: "suppliers",
        title: "Suppliers",
        messageId: "sidebar.menulinks.suppliers",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/suppliers",
      },
      {
        id: "products",
        title: "Products",
        messageId: "sidebar.menulinks.products",
        type: "item",
        auth: ["super-admin", "finance", "sales"],
        url: "/products",
      },
      {
        id: "withdrawal",
        title: "Retiro de Dinero",
        messageId: "sidebar.menulinks.withdrawal",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/withdrawal",
      },
    ],
  },
  {
    id: "Contabilidad",
    title: "sidebar.menulinks.accounting",
    messageId: "sidebar.menulinks.accounting",
    type: "collapse",
    icon: "account_balance_wallet",
    auth: ["super-admin", "finance", "administrative", "sales"],
    children: [
      {
        id: "userAccountBalance",
        title: "sidebar.menulinks.userAccountBalance",
        messageId: "sidebar.menulinks.userAccountBalance",
        type: "item",
        auth: [
          "super-admin",
          "finance",
          "administrative",
          "accommodation-manager",
          "sales",
        ],
        url: "/user-account-balance",
        exact: true,
      },
      {
        id: "Cuentas",
        title: "sidebar.menulinks.accounts",
        messageId: "sidebar.menulinks.accounts",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/financial-entity-balance",
      },
      {
        id: "temporary-fund",
        title: "Temporary Fund",
        messageId: "sidebar.menulinks.temporaryFund",
        type: "item",
        auth: ["super-admin", "finance", "administrative"],
        url: "/temporary-fund",
        exact: true,
      },
      {
        id: "Intercambios",
        title: "sidebar.menulinks.exchanges",
        messageId: "sidebar.menulinks.exchanges",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/exchange-entity-balance",
      },
      {
        id: "transfers",
        title: "Transferencias",
        messageId: "sidebar.menulinks.transfers",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/transfers",
        exact: true,
      },
    ],
  },
  {
    id: "Hospedajes",
    title: "sidebar.menulinks.accommodation",
    messageId: "sidebar.menulinks.accommodation",
    type: "collapse",
    icon: "local_hotel",
    auth: ["super-admin", "accommodation-manager", "sales"],
    children: [
      {
        id: "Dashboard Accommodation",
        title: "sidebar.menulinks.dashboard",
        messageId: "sidebar.menulinks.dashboard",
        type: "item",
        auth: ["super-admin", "accommodation-manager"],
        url: "/accommodation-dashboard",
      },
      {
        id: "Check IN / OUT",
        title: "sidebar.menulinks.accommodationStay",
        messageId: "sidebar.menulinks.accommodationStay",
        type: "item",
        auth: ["super-admin", "accommodation-manager", "sales"],
        url: "/accommodation-stay",
      },
      {
        id: "Propiedades",
        title: "sidebar.menulinks.properties",
        messageId: "sidebar.menulinks.properties",
        type: "item",
        auth: ["super-admin", "accommodation-manager"],
        url: "/accommodation",
      },
      {
        id: "Grupos de Propiedades",
        title: "sidebar.menulinks.accommodationGroup",
        messageId: "sidebar.menulinks.accommodationGroup",
        type: "item",
        auth: ["super-admin", "accommodation-manager"],
        url: "/accommodation-group",
      },
    ],
  },
  {
    id: "Configuración Contable",
    title: "sidebar.financeSettings",
    messageId: "sidebar.financeSettings",
    type: "collapse",
    icon: "settings",
    auth: ["super-admin", "finance"],
    children: [
      {
        id: "price-list",
        title: "Price List",
        messageId: "sidebar.menulinks.priceList",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/price-list",
      },
      {
        id: "organizations",
        title: "Organizations",
        messageId: "sidebar.menulinks.organizations",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/organizations",
      },
      {
        id: "financial-entities",
        title: "Financial Entities",
        messageId: "sidebar.menulinks.financialEntities",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/financial-entities",
      },
      {
        id: "exchange-entities",
        title: "Exchange Entities",
        messageId: "sidebar.menulinks.exchangeEntities",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/exchange-entities",
      },
      {
        id: "productCategories",
        title: "Product Categories",
        messageId: "sidebar.menulinks.productCategories",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/product-categories",
      },
      {
        id: "resources",
        title: "Resources",
        messageId: "sidebar.menulinks.resources",
        type: "item",
        auth: ["super-admin", "finance"],
        url: "/resources",
      },
    ],
  },
  {
    id: "others",
    title: "Others",
    messageId: "sidebar.menulinks.others",
    type: "collapse",
    icon: "info",
    auth: ["super-admin", "appa", "ppa"],
    children: [
      {
        id: "meteorology",
        title: "Meteorology",
        messageId: "sidebar.menulinks.meteorology",
        type: "item",
        auth: ["super-admin", "appa", "ppa"],
        url: "/others/meteorology",
        exact: true,
      },
      {
        id: "library",
        title: "Library",
        messageId: "sidebar.menulinks.library",
        type: "item",
        auth: ["super-admin", "appa", "ppa"],
        url: "/others/library",
        exact: true,
      },
      {
        id: "contacts",
        title: "Contacts",
        messageId: "sidebar.menulinks.contacts",
        type: "item",
        auth: ["super-admin", "appa", "ppa"],
        url: "/others/contacts",
        exact: true,
      },
    ],
  },
];
export default routesConfig;
